import styled from 'styled-components'

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 100%;
`

export const LoginCard = styled.div`
  width: 400px;
  padding: 80px 40px;
  gap: 20px;
  border-radius: 3px;
  background: #FFFFFF;
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const LogoutCard = styled(LoginCard)`

`

export const LoginCardHeaderImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 52px;
  object-fit: contain;
`

export const LoginCardHeading = styled.label`
  font-family: Lato;
  font-size: 1.5rem;
  line-height: 32px;
  font-weight: 400;
  color: #1e212a;
  margin-bottom: 0;
`

export const LogoutCardHeading = styled(LoginCardHeading)`
  text-align: center;
`

export const LoginCardSubHeading = styled.label`
  font-family: Lato;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #0D0D0F;
  margin-bottom: 0;
`

export const LoggedInUserMessage = styled.p`
  font-family: Lato;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #0D0D0F;
  margin-bottom: 0;
`

export const OutlierAuth0Button = styled.button`
  width: 312px;
  height: 48px;
  border-radius: 5px;
  background-color: #0D0D0F;
  font-family: Lato;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  padding: 12px 24px;
  cursor: pointer;
  color: #FFFFFF;

  &:focus, &:hover {
    box-shadow: 0 0 0 4px rgba(0,123,173, 0.15), inset 0 0 0 150px rgba(0,123,173, 0.15) !important;
  }
`

export const LoginButtonDivider = styled.p`
  font-family: Lato;
  font-weight: 500;
  font-size: 14px;
  line-height: 10px;
  color: #0D0D0F;
  margin: 0;
`

export const RealizeLoginButton = styled.button`
  width: 312px;
  height: 48px;
  border-radius: 5px;
  background-color: #FFFFFF;
  border: 1px solid #5B6067;
  font-family: Lato;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding: 12px 24px;
  cursor: pointer;
  color: #0D0D0F;
  display: flex;
  justify-content: center;
`
export const RealizeLogo = styled.img`
  width: 60px;
  height: 16px;
  margin-right: 8px;
`
