import book from './assets/icons/book.svg'
import barChart from './assets/icons/bar-chart.svg'
import enrollmentManagement from './assets/icons/enrollment-management.svg'
import folder from './assets/icons/folder.svg'
import openInNew from './assets/icons/open-in-new.svg'
import InProgress from './assets/icons/work-in-progress.svg'
import Pen from './assets/icons/pen.svg'
import windowStack from './assets/icons/window-stack.svg'
import announcements from './assets/icons/announcements.svg'
import miscIcon from './assets/icons/icon-misc.svg'
import calendarPlus from './assets/icons/calendar-plus.svg'
import lock from './assets/icons/lock.svg'
import flagIcon from './assets/icons/flag.svg'

import { GGU_RELATIONSHIP, IS_SSO_USER } from './constants'
import config from './config'
import { COURSE_ACCESS_TYPES } from './constants/course'

const {
  announcements: announcementsFlag,
  plos: plosFlag
} = config.getFlags()

const { name: UPITT_EXTENDED } = COURSE_ACCESS_TYPES[2]
const isGGURelationship = (relationshipName) => relationshipName === GGU_RELATIONSHIP

const getCards = ({ enrollmentOptions = [], liveProctoring, relationshipName } = {}) => {
  return [
    ...(announcementsFlag
      ? [
          {
            to: '/announcements',
            icon: announcements,
            name: 'Announcements',
            includeFor: [GGU_RELATIONSHIP],
            description:
              'Email students and post the message to the course homepage.'
          }
        ]
      : []),
    {
      to: '/enroll-students',
      icon: book,
      name: 'Enroll Students',
      excludeFor: [GGU_RELATIONSHIP, IS_SSO_USER],
      description: 'Sign up students for a course'
    },
    {
      to: '/student-progress',
      icon: barChart,
      name: 'Student Progress',
      description: 'Inspect student progress and grades'
    },
    {
      to: '/enrollment-management',
      icon: enrollmentManagement,
      name: 'Enrollment Management',
      excludeFor: [GGU_RELATIONSHIP, IS_SSO_USER],
      description: 'Submit drop or withdrawal requests'
    },
    ...(enrollmentOptions.includes(UPITT_EXTENDED) && liveProctoring
      ? [
          {
            icon: lock,
            to: '/unlock-exams',
            name: 'Unlock Exams',
            description: 'Unlock exams for students'
          }
        ]
      : []),
    ...(config.hasAcademicIntegrityFlag && liveProctoring
      ? [
          {
            to: '/academic-integrity',
            icon: flagIcon,
            name: 'Academic integrity',
            description: 'Review and report academic integrity incidents'
          }
        ]
      : []),
    {
      externalLink:
        'https://support.savvas.com/support/s/document-item?bundleId=Outlier-by-Savvas-User-Guide&topicId=Introduction_to_Outlier_by_Savvas.html&_LANG=enus&anchor=navPoint_1',
      icon: folder,
      name: isGGURelationship(relationshipName)
        ? 'Files'
        : 'Outlier by Savvas User Guide',
      description: 'Open Outlier by Savvas resource library',
      subIcon: openInNew
    },
    {
      to: '/course-materials',
      icon: windowStack,
      name: 'Course Materials',
      description: 'View your course materials'
    },
    {
      icon: InProgress,
      name: 'Gradebook',
      excludeFor: [GGU_RELATIONSHIP],
      description: 'This tool is in development'
    },
    {
      to: '/writing-grade-center',
      icon: Pen,
      name: 'Writing Grade Center',
      includeFor: [GGU_RELATIONSHIP],
      description: 'Read and grade the assignments students have submitted'
    },
    {
      icon: calendarPlus,
      to: '/assessment-extensions',
      name: 'Assessment Extensions',
      includeFor: [GGU_RELATIONSHIP],
      description: 'View all assessment extensions and create new ones'
    },
    ...(plosFlag
      ? [
          {
            icon: miscIcon,
            to: '/program-level-outcomes',
            name: 'PLOs',
            includeFor: [GGU_RELATIONSHIP],
            description: 'Assess student work against Program-Level Outcomes'
          }
        ]
      : [
          {
            icon: InProgress,
            name: 'PLOs',
            description: 'This tool is in development'
          }
        ])
  ]
}

export default getCards
