import React, { useEffect, useCallback } from 'react'
import usePromise from '../../../hooks/usePromise/usePromise'
import api from '../../../api'
import { Link, ListGroup, StyledFooter } from './Footer.styled'
import DropdownImgURL from '../../../assets/icons/feedback-icon.png'
import { GGU_RELATIONSHIP } from '../../../constants'
import { GGU_USERSNAP_KEY, NON_GGU_USERSNAP_KEY } from '../../../constants/userSnapKeys'
import { PRIVACY_URL, TERMS_URL } from '../../../constants/domains'

const Footer = () => {
  const {
    data: relationship,
    isLoading,
    error,
    manualPromiseTrigger
  } = usePromise(useCallback(() => api.getPartnerRelationships(), []))

  const isUsersnapLoaded = () => {
    const isScriptLoaded = document.getElementById('usersnap')
    if (isScriptLoaded) return true
  }

  const addFeedBackScript = apiKey => {
    window.onUsersnapCXLoad = api => {
      window.Usersnap = api
      api.init({
        button: null
      })

      // Feedback customized icon
      const img = document.createElement('img')
      img.src = DropdownImgURL
      img.setAttribute('id', 'feedback_icon')
      img.setAttribute('alt', 'Contact Outlier')

      img.addEventListener('click', () => api.open())
      document.getElementsByTagName('body')[0].appendChild(img)
    }

    const script = document.createElement('script')
    script.async = 1
    script.id = 'usersnap'
    script.src = `https://widget.usersnap.com/load/${apiKey}?onload=onUsersnapCXLoad`
    document.getElementsByTagName('head')[0].appendChild(script)
  }

  useEffect(() => {
    if (isUsersnapLoaded()) return console.log('returning')
    manualPromiseTrigger()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (error || !relationship) return
    const { relationshipName } = relationship?.fields || {}

    const USERSNAP_KEY = relationshipName === GGU_RELATIONSHIP
      ? GGU_USERSNAP_KEY
      : NON_GGU_USERSNAP_KEY

    addFeedBackScript(USERSNAP_KEY)
  }, [isLoading, relationship, error])

  return (
    <StyledFooter>
      <div className='d-flex position-relative'>
        <ListGroup>
          <li className='d-inline-block'>
            <Link
              href={TERMS_URL}
              target='_blank'
              rel='noopener noreferrer'
            >
              <span>Terms of Use</span>
            </Link>
          </li>
          <li className='d-inline-block'>
            <Link
              href={PRIVACY_URL}
              target='_blank'
              rel='noopener noreferrer'
            >
              <span>Privacy Policy</span>
            </Link>
          </li>
        </ListGroup>
      </div>
    </StyledFooter>
  )
}

export default Footer
