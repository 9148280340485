import axios from 'axios'
import axiosRetry from 'axios-retry'
import qs from 'qs'
import config from './config'
import { OUTLIER_API_BASE_URL } from './constants/domains'
import {
  filterCurrentPartnerCourses,
  filterPartnerCourses
} from './utilities/course'
import { addCohortStatusAndFormatCohorts } from './utilities/cohort'
import { COURSE_NAMES_MAPPING } from './constants/course'
import { NO_ASSIGNMENT } from './constants/writingGradeCenter'
import { useAuthStore, useUserStore } from '@outlier-org/lst-auth-react'
import { ApiContext, get, post, put, deleteRequest, getAuthToken, useLstApiCacheStore } from '@outlier-org/lst-api-client-react'
import { isOutlierAccount } from './utilities/user'
import { getUrlSearchParams } from '@outlier-org/lst-common-react'

axiosRetry(axios, {
  retries: 1,
  retryDelay: (retryCount) => retryCount * 1000,
  shouldResetTimeout: true,
  retryCondition: (error) => {
    const is404Response = error?.response?.status === 404

    return !is404Response
  },
  onRetry: (retryCount, error, requestConfig) => {
    const { status } = error?.response || {}
    const { url } = requestConfig || {}

    console.error(
      'ON Error retry: ', JSON.stringify({ retryCount, statusCode: status, url })
    )
  }
})

const defaultApiHostUrl = config.getApiHost()

const logError = (data) => {
  return post('/monitor/log-frontend-error', data)
}

const timeoutPromise = (promise, ms) => {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error('Request timed out'))
    }, ms)

    promise.then(
      (res) => {
        clearTimeout(timeoutId)
        resolve(res)
      },
      (err) => {
        clearTimeout(timeoutId)
        reject(err)
      }
    )
  })
}

const getAccessPermissions = async () => {
  return get('/partners/access/permissions')
}

const getPartnerId = async () => {
  return get('/student/student-id')
}

const getStudentStatuses = async (queryParams) => {
  return get('/partners/student-statuses', { queryParams })
}

export const getCourses = async () => {
  const coursesResponse = await get('/courses', {}, ApiContext.OUTLIER_API, {}, { cache: true })

  if (coursesResponse.error) return coursesResponse

  const formattedCourses = coursesResponse.data.map(course => {
    const { id, name } = course
    return {
      ...course,
      name: COURSE_NAMES_MAPPING[id] || name
    }
  })

  return {
    data: formattedCourses,
    error: null
  }
}

export const getProspectsData = async studentEmail => {
  return get(`/prospects/prospect-data?studentEmail=${encodeURIComponent(studentEmail)}`, {}, ApiContext.OUTLIER_API, {}, { cache: true })
}

export const getAvailableTokens = async (relationshipId = null, body) => {
  const url = relationshipId
    ? `/partners/available-tokens?relationshipId=${relationshipId}`
    : '/partners/available-tokens'
  return post(url, body)
}

export const enrollStudents = async (body) => {
  const fullUrl = `${defaultApiHostUrl}/partners/enroll-partner-students`

  console.info(`API-post start ${fullUrl}, body: `, body)

  try {
    const promise = post(fullUrl, body)

    const { data } = await timeoutPromise(promise, 15000)

    console.info(`API-post done ${fullUrl}`)

    return {
      data,
      error: null
    }
  } catch (error) {
    console.error(`API-post error ${fullUrl}`)

    if (error?.message === 'Request timed out') {
      return {
        awaitingResponse: true
      }
    }

    return {
      error,
      data: null
    }
  }
}

export const getActiveEnrollmentCohorts = async (relationshipId) => {
  const response = await get(`/partners/active-cohorts/${relationshipId}`, {}, ApiContext.OUTLIER_API, {}, { cache: true })
  return response
}

export const getPartnerCohorts = async (isMulti) => {
  const url = isMulti ? '/partners/cohorts?allRelationships=true' : 'partners/cohorts'
  const partnerCohortsResponse = await get(url, {}, ApiContext.OUTLIER_API, {}, { cache: true })

  if (partnerCohortsResponse.error) return partnerCohortsResponse

  const { cohorts } = partnerCohortsResponse.data

  return {
    ...partnerCohortsResponse,
    data: {
      ...partnerCohortsResponse.data,
      cohorts: addCohortStatusAndFormatCohorts(cohorts)
    }
  }
}

export const bulkStatusModification = async (cohortId, body) => {
  return put(`/partners/bulk-status-modification/${cohortId}`, body)
}

export const getCohortStudents = cohortId => {
  return get(`/students/${cohortId}`)
}

export const getPartnerCohortStudents = cohortId => {
  return get(`/partners/students/${cohortId}`)
}

export const getCatalogCourses = async () => {
  const catalogResponse = await get('/dato/catalog', {}, ApiContext.OUTLIER_API, {}, { cache: true })

  // If response status is unauthorized and api host url is not production or
  // If response data is empty and api host url is not production,fetch it again
  // with production api url.

  const shouldTryWithProductionApi = defaultApiHostUrl !== OUTLIER_API_BASE_URL &&
    (catalogResponse?.error?.response?.status === 401 ||
      Object.keys(catalogResponse?.data).length === 0)
  if (shouldTryWithProductionApi) {
    return get('/dato/catalog', {}, ApiContext.OUTLIER_API, {}, { cache: true })
  }

  return catalogResponse
}

export const getCourseData = courseId => {
  if (!courseId) return
  return get(`/dato/files/${courseId}/${courseId}`, {}, ApiContext.OUTLIER_API, {}, { cache: true })
}

export const getSectionData = (courseId, sectionId) => {
  return get(`/dato/files/${courseId}/${sectionId}`, {}, ApiContext.OUTLIER_API, {}, { cache: true })
}

export const getMultipleSectionData = async (courseId, sectionIds) => {
  const response = await Promise.all(
    sectionIds.map(sectionId => getSectionData(courseId, sectionId))
  )

  return {
    data: response
      ?.filter((data) => !data.error)
      ?.map(({ data }) => data)
  }
}

export const getCourseraGrades = ({ courseId, cohortId, studentEmail }) => {
  return get(`/student/coursera-progress/${courseId}/${cohortId}/${studentEmail}`, {}, ApiContext.OUTLIER_API, {}, { cache: true })
}

export const getStudentProgress = ({
  courseId,
  cohortId,
  isCurrentCohort,
  studentEmail
}) => {
  const url = isCurrentCohort
    ? `/student/progress/${courseId}/${studentEmail}`
    : `/student/progress/${courseId}/${cohortId}/${studentEmail}`

  return get(url)
}

export const updateStudentProgress = async ({
  key, courseId, payload
}) => {
  const url = `/student/progress/multiple-students/${key}/${courseId}`
  return put(url, payload)
}

export const getCohortSyllabus = cohortId => {
  return get(`/cohort-syllabus/${cohortId}`, {}, ApiContext.OUTLIER_API, {}, { cache: true })
}

export const getPartnerCourses = async () => {
  const [allCourses, catalogCourses] = await Promise.all([
    getCourses(),
    getCatalogCourses()
  ])

  if (allCourses.error || catalogCourses.error) {
    return { data: [], error: null }
  }

  return {
    data: filterPartnerCourses(allCourses.data, catalogCourses.data),
    error: null
  }
}

export const getCurrentPartnerCourses = async () => {
  const [allCourses, currentRelationship] = await Promise.all([
    getCourses(),
    getPartnerRelationships()
  ])

  if (allCourses.error || currentRelationship.error) {
    return { data: [], error: null }
  }

  const { data: { fields: { relationshipName } } } = currentRelationship || {}

  return {
    data: filterCurrentPartnerCourses(allCourses.data, relationshipName),
    error: null
  }
}

export const getStudentsGradeData = (courseId, queryParams, cache = false) => {
  const queryString = qs.stringify(queryParams)
  return get(`/student/grades/sections/${courseId}?${queryString}`, {}, ApiContext.OUTLIER_API, {}, { cache })
}

export const getStudentsCurrentGrade = (courseId, cohortName, queryParams = {}) => {
  const queryString = qs.stringify(queryParams)
  return get(
    `/student/current-grade/${courseId}/${encodeURIComponent(cohortName)}?${queryString}`
  )
}

export const updateAuth0User = async body => {
  return put('/update/auth0/user', body, ApiContext.AUTH0_API)
}

export const sendWelcomeEmail = async body => {
  return post('/welcome-email', body)
}

export const getGradesCSV = async body => {
  return post('/partners/grades-export', body)
}

export const sendErrorEnrollmentEmail = async formData => {
  return post(
    '/partners/error-enrollment-email',
    formData,
    ApiContext.OUTLIER_API,
    { 'Content-type': 'multipart/form-data' }
  )
}

export const getPartnerRelationships = async (isMulti) => {
  const url = isMulti ? '/partners/relationship?multiple=true' : '/partners/relationship'
  return get(url, {}, ApiContext.OUTLIER_API, {}, { cache: true })
}

export const sendInvoiceRequestEmail = async body => {
  return put('/partners/request-invoice-email', body)
}

async function getStudentSubmissions (courseId, queryParams) {
  return get(`/partners/student-grade/${courseId}/assignments?${queryParams}`, {}, ApiContext.OUTLIER_API, {}, { cache: true })
}

async function getAllCohorts (queryParams) {
  return get(`/cohorts?${qs.stringify(queryParams)}`, {}, ApiContext.OUTLIER_API, {}, { cache: true })
}

async function getCohortMilestones (cohortId) {
  return get(`/partners/cohort-milestones/${cohortId}`, {}, ApiContext.OUTLIER_API, {}, { cache: true })
}

async function getWritingAssignment ({
  courseId,
  cohortID,
  assignmentUUID,
  studentEmail,
  fileName,
  multiPartAssignment
}) {
  const token = await getAuthToken()
  try {
    const queryParams = `studentEmail=${studentEmail}${multiPartAssignment ? '&multiPartAssignment=true' : ''}`
    const url = `${defaultApiHostUrl}/student/writing-assignment/${courseId}/${cohortID}/${assignmentUUID}/${fileName || ''}?${queryParams}`

    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'arraybuffer'
    })
    const { data } = response
    if (data && data.error) return { error: data.error }
    return response
  } catch (e) {
    console.error('Error when getting writing assignment', e.message)
    return { error: NO_ASSIGNMENT }
  }
}

async function getStudentsByCohort (cohortId) {
  return get(`/students/${cohortId}`, {}, ApiContext.OUTLIER_API, {}, { cache: true })
}

async function submitTrackedEvent (rawData) {
  console.info('API start submitTrackedEvent')
  const allowedKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
  const params = qs.parse(window.location.search.slice(1))
  const utmPropertiesArray = Object.keys(params)
    .filter(key => allowedKeys.includes(key))
    .map(key => ([key, params[key]]))
  const utmProperties = Object.fromEntries(utmPropertiesArray)

  const properties = { ...rawData.properties, ...utmProperties }
  const eventData = { ...rawData, properties }

  return post('/partners/analytics/track-event', eventData)
}

async function setStudentProgress ({ key, courseId }, studentEmail, sectionData) {
  return put(
    `/partners/student-exam-grade/${key}/${encodeURIComponent(studentEmail)}/${courseId}`,
    sectionData
  )
}

async function putKamiDocument (
  formData, { studentEmail, courseId, cohortId, assignmentUUID }
) {
  return post(
    `/partners/kami/upload/document/${studentEmail}/${courseId}/${cohortId}/${assignmentUUID}`,
    formData, ApiContext.OUTLIER_API, { 'Content-type': 'multipart/form-data' }
  )
}

async function getKamiDocument ({
  studentEmail,
  courseId,
  cohortId,
  assignmentUUID,
  documentIdentifier
}) {
  return get(
    `/partners/kami/document/${studentEmail}/${courseId}/${cohortId}/${assignmentUUID}/${documentIdentifier}`,
    {},
    ApiContext.OUTLIER_API,
    {},
    { cache: true }
  )
}

async function createKamiViewSession (studentEmail, body) {
  return post(`/partners/kami/create/view-session/${studentEmail}`, body)
}

async function putAssignmentFeedbackFile ({
  studentEmail,
  courseId,
  cohortId,
  assignmentUUID,
  formData
}) {
  return put(
    `/partners/assignment-feedback-file/${studentEmail}/${courseId}/${cohortId}/${assignmentUUID}`,
    formData, ApiContext.OUTLIER_API, { 'Content-type': 'multipart/form-data' }
  )
}

async function deleteAssignmentFeedbackFile ({
  studentEmail,
  courseId,
  cohortId,
  assignmentUUID
}) {
  return deleteRequest(
    `/partners/assignment-feedback-file/${studentEmail}/${courseId}/${cohortId}/${assignmentUUID}`
  )
}

async function getAssignmentFeedbackFile ({ courseId, cohortId, assignmentUUID, studentEmail }) {
  const token = await getAuthToken()
  const url = `${defaultApiHostUrl}/student/assignment-feedback-file/${courseId}/${cohortId}/${assignmentUUID}?studentEmail=${studentEmail}`

  try {
    const { data } = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob'
    })
    return data
  } catch (e) {
    console.error(`API error in getAssignmentFeedbackFile: ${e.message}`)
    return { error: e.message }
  }
}

async function getStudentData (queryParams) {
  return get(`/student/student-data?${queryParams}`, {}, ApiContext.OUTLIER_API, {}, { cache: true })
}

async function putAssignmentModifications (
  studentEmail,
  { courseId, cohortId },
  editData
) {
  const url = cohortId
    ? `/partners/assignment-grade-modification/${studentEmail}/${courseId}/${cohortId}`
    : `/partners/assignment-grade-modification/${studentEmail}/${courseId}`
  return put(url, editData)
}

async function putStudentExamModifications (
  studentEmail,
  { courseId, cohortId },
  editData
) {
  const url = cohortId
    ? `/partners/exam-grade-modification/${studentEmail}/${courseId}/${cohortId}`
    : `/partners/exam-grade-modification/${studentEmail}/${courseId}`
  return put(url, editData)
}

async function putStudentSectionModification (
  studentEmail,
  { courseId, cohortId, progressKey },
  editData) {
  const url = cohortId
    ? `/partners/modify-section-grade/${studentEmail}/${progressKey}/${courseId}/${cohortId}`
    : `/partners/modify-section-grade/${studentEmail}/${progressKey}/${courseId}`
  return put(url, editData)
}

async function getParticipationModifications (studentEmail, courseId) {
  return get(`/partners/participation-grade-modification/${studentEmail}/${courseId}/participation`)
}

async function putParticipationModifications (
  studentEmail,
  { courseId, cohortId },
  editData
) {
  const url = cohortId
    ? `/partners/participation-grade-modification/${studentEmail}/${courseId}/${cohortId}`
    : `/partners/participation-grade-modification/${studentEmail}/${courseId}`
  return put(url, editData)
}

async function getAssignmentModifications (courseId, studentEmail) {
  get(`/partners/assignment-grade-modification/${studentEmail}/${courseId}`, undefined, ApiContext.OUTLIER_API, {}, {
    validateStatus: status => status
  })
}

async function getStudentSectionModifications (courseId, studentEmail) {
  return get(`/partners/modify-section-grade/${studentEmail}/${courseId}`)
}

async function getExamGradeModifications (courseId, studentEmail) {
  return get(`/partners/exam-grade-modification/${studentEmail}/${courseId}`)
}

async function getStudentCourses () {
  return get('/student/courses', {}, ApiContext.OUTLIER_API, {}, { cache: true })
}

async function getStudentCoursesByEmail (studentEmail) {
  return get(`/partners/courses/${encodeURIComponent(studentEmail)}`)
}

async function getExamExtensionsTable ({
  offset,
  cohortFilter = '',
  studentFilter = '',
  courseFilter = '',
  isGGU = false
}) {
  let url = `/partners/ggu-exam-extensions?limit=10&offset=${offset}&sort=updatedAt`
  url += cohortFilter !== '' ? `&cohortId=${cohortFilter}` : ''
  url += studentFilter !== '' ? `&search=${encodeURIComponent(studentFilter)}` : ''
  url += courseFilter !== '' ? `&courseId=${courseFilter}` : ''
  url += `&isGGU=${isGGU}`

  return get(url)
}

async function getStudentExtensionRecords (studentIds) {
  return post('/partners/assessment-extensions', { studentIds })
}

async function getStudentDataByEmailOrId (
  studentIdOrEmail,
  isGGU = false
) {
  const url = `/partners/student-data/${studentIdOrEmail}?isGGU=${isGGU}`
  return get(url)
}

async function addAssessmentExtensions (extensionData) {
  return post('/partners/create/assessment-extensions', extensionData)
}

async function getCohortById (cohortId) {
  return get(`/partners/cohorts/details/${cohortId}`)
}

async function putAssessmentExtension (extensionData) {
  return put('/partners/update/assessment-extensions', extensionData)
}

async function getStudentsByExam (courseId, cohortId, examId, relationshipId) {
  const url = `/partners/students/exam-status/${courseId}/${cohortId}/${examId}`
  if (relationshipId) {
    return get(`${url}/${relationshipId}`)
  }
  return get(url)
}

async function getMaxParticipationGrade (courseId) {
  return get(`/course/participation-max/${courseId}`)
}

async function checkStudentsEligibility (body) {
  return post('/partners/check-student-eligibility', body)
}

/**
 * Validates and updates the user cache.
 *
 * This function checks if the cached student ID matches the current user's student ID.
 * If they do not match, it removes the cache storage for the OUTLIER_API context.
 * If the student ID exists, it sets the cache key with the current student ID.
 *
 * @async
 * @function validateUserCache
 * @returns {Promise<void>} A promise that resolves when the cache validation and update are complete.
 */
async function validateUserCache () {
  const { setCacheKey, removeCacheStorage, getCacheKey } = useLstApiCacheStore.getState()
  const { user } = useUserStore.getState()
  const { studentId } = user || {}

  const cacheStudentId = await getCacheKey('studentId')
  if (cacheStudentId && cacheStudentId !== studentId) {
    removeCacheStorage(ApiContext.OUTLIER_API)
  }

  if (studentId) {
    await setCacheKey('studentId', studentId)
  }
}

async function handlePostAuthCallback () {
  await validateUserCache()
  let permissionsResponse, partnerRelationship
  const setUserAuthorization = useAuthStore.getState().setUserAuthorization

  try {
    [permissionsResponse, partnerRelationship] = await Promise.all([
      getAccessPermissions(),
      getPartnerRelationships()
    ])
  } catch (error) {
    console.error('Failed to get access permissions and partner relationships:', error)
    setUserAuthorization({
      relationship: {},
      active: false
    })
    return
  }

  setUserAuthorization({
    relationship: partnerRelationship?.data || {},
    active: !!permissionsResponse?.data.active || false
  })

  const user = useUserStore.getState().user
  const isAdmin = isOutlierAccount(user?.email)
  const searchParams = getUrlSearchParams()
  const emailVerifiedParam = searchParams.get('email-verified')
  const shouldVerifyUser = !user.email_verified && (isAdmin || emailVerifiedParam)

  if (shouldVerifyUser) {
    try {
      return await updateAuth0User({ email_verified: true })
    } catch (error) {
      console.error(error)
    }
  }
}

const api = {
  updateStudentProgress,
  getProspectsData,
  getStudentsCurrentGrade,
  getPartnerId,
  getCohortSyllabus,
  getGradesCSV,
  getCourseraGrades,
  getStudentProgress,
  getCourseData,
  getSectionData,
  getStudentsGradeData,
  getStudentStatuses,
  getCourses,
  getCohortStudents,
  getPartnerCohortStudents,
  updateAuth0User,
  sendWelcomeEmail,
  getPartnerCourses,
  getAvailableTokens,
  getAccessPermissions,
  getPartnerCohorts,
  getActiveEnrollmentCohorts,
  bulkStatusModification,
  getCurrentPartnerCourses,
  sendErrorEnrollmentEmail,
  getPartnerRelationships,
  sendInvoiceRequestEmail,
  enrollStudents,
  getStudentSubmissions,
  getAllCohorts,
  getCohortMilestones,
  getWritingAssignment,
  getStudentsByCohort,
  getStudentsByExam,
  submitTrackedEvent,
  setStudentProgress,
  putKamiDocument,
  getKamiDocument,
  createKamiViewSession,
  putAssignmentFeedbackFile,
  deleteAssignmentFeedbackFile,
  getAssignmentFeedbackFile,
  getStudentData,
  getAssignmentModifications,
  putAssignmentModifications,
  getParticipationModifications,
  putParticipationModifications,
  putStudentExamModifications,
  putStudentSectionModification,
  getStudentSectionModifications,
  getExamGradeModifications,
  getStudentCourses,
  getCatalogCourses,
  logError,
  getExamExtensionsTable,
  getStudentExtensionRecords,
  getStudentDataByEmailOrId,
  getStudentCoursesByEmail,
  addAssessmentExtensions,
  getCohortById,
  putAssessmentExtension,
  getMaxParticipationGrade,
  checkStudentsEligibility,
  handlePostAuthCallback
}

export default api
