import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router } from 'react-router-dom'
import 'font-awesome/css/font-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from './App'
import AppGuard from './components/AppGuard/AppGuard'
import reportWebVitals from './reportWebVitals'
import { AuthProvider, getRumbaSsoServiceUrl } from '@outlier-org/lst-auth-react'
import api from './api'

import 'react-datepicker/dist/react-datepicker.css'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import { unrestrictedRoutes } from './utilities/authUtils'

const {
  REACT_APP_ID: appId,
  REACT_APP_SSO_AUTH_SAPI_BASE_URL: authGatewayBaseUrl,
  REACT_APP_SSO_AUTH_CONTEXT_ID: authContextId,
  REACT_APP_SSO_AUTH_GATEWAY_CLIENT_ID: authGatewayClientId,
  REACT_APP_SSO_BASE_URL: ssoBaseUrl,
  REACT_APP_SSO_RBS_TOKEN_GRANT_TYPE: rbsTokenGrantType
} = process.env

/**
 * Configuration object for authentication.
 *
 * @typedef {Object} AuthConfig
 * @property {string} appId - The application ID, defaults to 'student-dashboard'.
 * @property {string} authGatewayBaseUrl - The base URL for the authentication gateway.
 * @property {string} authContextId - Unique ID from the dashboard.
 * @property {string} authGatewayClientId - Client ID for the authentication gateway, to be confirmed with devops.
 * @property {boolean} useUserRoleAsRbsScope - Flag to use user role as RBS scope.
 * @property {string} ssoBaseUrl - The base URL for single sign-on.
 * @property {string} rbsTokenGrantType - The grant type for RBS token.
 * @property {string} authCallbackRoute - The route for authentication callback, defaults to '/'.
 * @property {boolean} includeLicensedProducts - Flag to include licensed products, defaults to false.
 * @property {boolean} includePolicy - Flag to include policy, defaults to true.
 * @property {boolean} redirectToCustomError - Flag to redirect to custom error page, defaults to false.
 * @property {string} serviceUrl - The service URL, defaults to the current window location origin.
 * @property {Array<string>} publicRoutes - List of public routes.
 * @property {Function} onPostAuth - Callback function to handle post-authentication.
 */
export const lstAuthConfig = {
  appId: appId || 'partners-dashboard',
  authGatewayBaseUrl,
  authContextId, // Unique Id - from dashboard
  authGatewayClientId, // Need to check with devops
  useUserRoleAsRbsScope: true,
  ssoBaseUrl,
  rbsTokenGrantType,
  authCallbackRoute: '/',
  includeLicensedProducts: false,
  includePolicy: true,
  redirectToCustomError: true,
  serviceUrl: getRumbaSsoServiceUrl(),
  publicRoutes: unrestrictedRoutes,
  onPostAuth: api.handlePostAuthCallback
}

ReactDOM.render(
  <ErrorBoundary>
    <Router>
      <AuthProvider initialConfig={lstAuthConfig}>
        <AppGuard>
          <App />
        </AppGuard>
      </AuthProvider>
    </Router>
  </ErrorBoundary>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
