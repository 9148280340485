import React from 'react'
import NoAccess from '../NoAccess/NoAccess'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import { SpinnerWrap } from '../LoadingSpinner/LoadingSpinner.styled'
import { useAuthStore, isPublicRoute } from '@outlier-org/lst-auth-react'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'

const AppGuard = ({ children }) => {
  const [isAuthenticated, login, isLoading, active, isAuthenticationFailed] = useAuthStore((state) => [
    state.isAuthenticated,
    state.login,
    state.isLoading,
    state?.userAuthorization?.active,
    state.isAuthenticationFailed
  ])

  if ((isAuthenticated && active) || isPublicRoute()) {
    return children
  }

  if (!isLoading && isAuthenticationFailed) {
    return <ErrorBoundary />
  }

  if (!isLoading && active !== false) {
    login()
  }

  if (active === false) {
    return <NoAccess />
  }

  return <SpinnerWrap><LoadingSpinner /></SpinnerWrap>
}
export default AppGuard
