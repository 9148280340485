import moment from 'moment'
import momentTZ from 'moment-timezone'
import { isDate, isNumber, isString } from 'lodash'

export const DATE_STRING_FORMAT = 'YYYY-MM-DD'
export const MONTH_DATE_FORMAT = 'MMM D'
export const DEADLINE_FORMAT = 'MMM D, hh:mm A'
export const MINUTES_IN_A_DAY = 24 * 60

/**
 * @param {number} input the time we want to change it to milliseconds
 * @param {string} unit the uni we want to use for the input like seconds, minutes, hours, days.
 * @returns {number} milliseconds
 */
export const getMilliSeconds = (input, unit) => {
  return moment.duration(input, unit).asMilliseconds()
}

export const getMidnight = dateString => {
  if (!dateString) return moment().startOf('day')
  return moment(dateString, DATE_STRING_FORMAT).startOf('day')
}

export const getTodayMidNight = () => {
  const now = new Date()
  now.setHours(0, 0, 0, 0)

  return now
}

export const changeTimezone = (date, IANATimezone) => {
  const invdate = new Date(date.toLocaleString('en-US', {
    timeZone: IANATimezone
  }))
  const diff = date.getTime() - invdate.getTime()
  return new Date(date.getTime() + diff)
}

export const convertEasternTimeToLocalTime = (
  dateString,
  timeString = 'T23:59:00'
) => {
  if (!dateString || typeof dateString !== 'string') return

  const date = new Date(dateString + timeString)
  if (!date || date.toString() === 'Invalid Date') return

  const easterTimeDate = changeTimezone(date, 'America/New_York')
  return easterTimeDate
}

export const getUserTimezone = () =>
  momentTZ.tz(momentTZ.tz.guess(true)).format('z')

export const getFormattedDate = (
  date, { showTimezone, format = 'M/DD/YYYY' } = {}
) => {
  if (!date) return ''

  const timezone = showTimezone
    ? getUserTimezone()
    : ''
  return `${moment(date).format(format)}${timezone ? ' ' + timezone : ''}`
}

export const getWeekNumber = (dateStart) => {
  if (!dateStart) return

  const currentDayWeekNumber = moment().week()
  const dateStartWeekNumber = moment(dateStart, DATE_STRING_FORMAT).week()
  return currentDayWeekNumber - dateStartWeekNumber + 1
}

export const getDateObject = (date, format = DATE_STRING_FORMAT) => {
  return typeof date === 'string' && format ? moment(date, format) : moment(date)
}

export const getFormattedEndDate = (
  dateStart,
  dateEnd,
  { inputFormat = DATE_STRING_FORMAT, showTimezone, returnFormat } = {}
) => {
  const date1 = getDateObject(dateStart, inputFormat).toDate()
  const date2 = getDateObject(dateEnd, inputFormat).toDate()
  if (!returnFormat && date1.getMonth() === date2.getMonth()) return date2.getDate()

  const options = { showTimezone, format: returnFormat || MONTH_DATE_FORMAT }
  return `${getFormattedDate(dateEnd, options)}`
}

export const getTimestamp = dateString => {
  return getDateObject(dateString).valueOf()
}

export const addDuration = params => {
  const {
    startDate,
    inputFormat = DATE_STRING_FORMAT,
    durationType = 'days',
    durationValue,
    formatString,
    returnTimestamp = false
  } = params || {}

  if (!startDate) return null

  const extendedTime = getDateObject(startDate, inputFormat).add(durationValue, durationType)

  return formatString
    ? extendedTime.format(formatString)
    : returnTimestamp ? extendedTime.valueOf() : extendedTime
}

export const addBusinessDays = params => {
  const {
    startDate,
    inputFormat = DATE_STRING_FORMAT,
    durationValue
  } = params || {}

  if (!startDate) return null

  let momentDate = getDateObject(startDate, inputFormat)
  let daysAdded = 0

  while (daysAdded < durationValue) {
    momentDate = momentDate.add(1, 'days')
    if (![0, 6].includes(momentDate.day())) daysAdded++
  }

  return momentDate
}

export const subtractDuration = params => {
  const {
    startDate,
    inputFormat = DATE_STRING_FORMAT,
    durationType = 'days',
    durationValue,
    formatString,
    returnTimestamp = false
  } = params || {}

  if (!startDate) return null

  const reducedTime = getDateObject(startDate, inputFormat)
    .subtract(durationValue, durationType)
  return formatString
    ? reducedTime.format(formatString)
    : returnTimestamp ? reducedTime.valueOf() : reducedTime
}

export const getWeekDay = ({
  dateStart,
  increment = 0,
  requiredDay,
  time,
  durationType = 'weeks',
  format = DATE_STRING_FORMAT
}) => {
  const nextWeek = addDuration({
    startDate: dateStart,
    durationType,
    durationValue: increment,
    inputFormat: format
  })
  const nextWeekDay = nextWeek.isoWeekday(requiredDay)
  const { hour, minute, second } = time || {}
  return time
    ? nextWeekDay.set({ hour, minute, second })
    : nextWeekDay
}

export const getWeekDurations = (startDate, duration) => {
  const isValidDate = moment(startDate).isValid()
  const isValidDuration = duration &&
    typeof (duration) === 'number' &&
    !isNaN(duration)

  if (!isValidDate || !isValidDuration) return []

  const SUNDAY = 0
  const isSunday = moment(startDate).day() === SUNDAY
  const modifiedStartDate = isSunday
    ? moment(startDate).add(1, 'days').format(DATE_STRING_FORMAT)
    : startDate

  let endDate = moment(modifiedStartDate).endOf('isoWeek')

  const weekDurations = [{
    weekStartDate: moment(startDate).format(),
    weekEndDate: endDate.format()
  }]

  for (let i = 2; i <= duration; i++) {
    const weekStartDate = moment(endDate)
      .add(1, 'days')
      .startOf('isoWeek')
      .format()

    const weekEndDate = moment(weekStartDate).endOf('isoWeek').format()

    weekDurations.push({
      weekStartDate,
      weekEndDate
    })

    endDate = weekEndDate
    // For the first week we just take the input startDate.
    // But for the rest of the weeks we take the endDate from the previous week and add 1 day.
    // For the endDates we take the start date of the week and add 6 days.
    // finally we push the created obj into weekDurations array and set the endDate variable to the newEndDate.
  }
  return weekDurations
}

export const getIntensiveCohortDays = (startDate, syllabus) => {
  const invalidDate = !startDate || new Date(startDate) === 'Invalid Date'

  if (invalidDate || !syllabus?.syllabusData?.length) return []

  let day = 0

  return syllabus?.syllabusData?.map((section, index) => {
    const newDate = new Date(startDate)
    newDate.setDate(newDate.getDate() + day)

    const isSunday = newDate.toDateString().includes('Sun')
    const isSaturday = newDate.toDateString().includes('Sat')

    if (isSunday) {
      day += 1
    }
    if (isSaturday) {
      day += 2
    }
    day++

    const sectionStartDate = new Date(startDate)
    sectionStartDate.setDate(sectionStartDate.getDate() + day - 1)

    return {
      isEmpty: !section.sections?.length,
      day: index + 1,
      date: sectionStartDate
    }
  })
    ?.filter(section => !section?.isEmpty)
}

export const timeStampToFormattedDate = (timestamp, hasTime, monthStyle = 'long') => {
  if (!timestamp) return 'N/A'
  const date = new Date(timestamp)
  const dateWithTime = { year: 'numeric', month: monthStyle, day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }
  return date.toLocaleString('en-US', hasTime ? dateWithTime : { dateStyle: 'long' })
}

export const secondsToHHMMSS = (seconds) => {
  const secondsString = String(seconds % 60).padStart(2, '0')
  const minutes = Math.floor(seconds / 60)
  const minutesString = String(minutes % 60).padStart(2, '0')
  const hoursString = String(Math.floor(minutes / 60)).padStart(2, '0')
  return `${hoursString}:${minutesString}:${secondsString}`
}

export const dateToSecondsSinceEpoch = (date) => {
  if (!date || !isDate(date)) return
  return Math.floor(date.getTime() / 1000)
}

export const getTimezoneShort = (seconds) => {
  if (!seconds || !isNumber(seconds)) return
  const date = new Date(seconds * 1000)
  const dateString = date.toLocaleString('en-US', { timeZoneName: 'long' })
  const timeZoneStartIndex = dateString.search(/( AM | PM )/)
  const timezoneLong = dateString.substring(timeZoneStartIndex + 4)
  if (timezoneLong === 'Coordinated Universal Time') return 'UTC'

  // Extract first character from each word of timezoneLong
  const timezoneShort = timezoneLong
    .split(' ')
    .map(word => word[0])
    .join('')
  return timezoneShort
}

export const addDotBtwDateAndTime = (dateString) => {
  if (!dateString || !isString(dateString)) return
  const formattedDate = dateString.replace(', ', ' · ')
  const lastSpacePosition = formattedDate.lastIndexOf(' ')

  return (
    formattedDate.substring(0, lastSpacePosition) +
    formattedDate.substring(lastSpacePosition + 1)
  )
}

export const formatWithoutYear = (date) => {
  if (!date || !isDate(date)) return
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    hour12: true
  })
}

export const standardizeToEasternTime = (date) => {
  if (!date || String(new Date(date)) === 'Invalid Date') return

  const convertedDate = new Date(date).toLocaleString('en-US', { timeZone: 'America/New_York' })
  const time = convertedDate.split(', ')[1].replace(/:\d\d\s/, ' ')
  const dateObj = new Date(convertedDate)

  let day = String(dateObj.getDate())
  day = day.length === 1 ? `0${day}` : day

  let month = String(dateObj.getMonth() + 1)
  month = month.length === 1 ? `0${month}` : month

  const year = String(dateObj.getFullYear()).slice(2, 4)

  return `${month}-${day}-${year} ${time}`
}

export const secondsSinceEpoch = () => {
  return Math.floor(Date.now() / 1000)
}

export const getDateString = (date, format = 'MM/DD/YYYY') => {
  if (!date) return ''

  return moment(date).format(format)
}

export const getDateAfterWeeks = (dateStart, weeks) => {
  if (!dateStart || !weeks) return

  if (String(new Date(dateStart)) === 'Invalid Date') return

  const WEEKDAYS = 7
  const daysDuration = WEEKDAYS * weeks
  const dateAfterWeeks = new Date(dateStart)
  dateAfterWeeks.setDate(dateAfterWeeks.getDate() + daysDuration)
  return dateAfterWeeks
}

export const getFormattedShortDateAndTime = (stringDate) => {
  const date = new Date(stringDate)
  if (date.toString() === 'Invalid Date') return

  const formattedDate = getDateString(new Date(date), 'MM/DD/YY')
  const formattedTime = new Date(date)
    .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    .toLowerCase()

  return [formattedDate, formattedTime]
}

export const diffDays = (startDate, endDate, isRounded = true) => {
  const date1 = new Date(startDate)
  const date2 = new Date(endDate)
  const invalidDate =
    date1.toString() === 'Invalid Date' || date2.toString() === 'Invalid Date'
  if (invalidDate) return

  let diff = (date1.getTime() - date2.getTime()) / 1000
  diff /= 60 * 60 * 24

  return Math.abs(isRounded ? Math.round(diff) : diff)
}

export const getDateStringWithComma = (date) => {
  const toDate = new Date(date)

  if (toDate.toString() === 'Invalid Date') return

  return toDate.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
}

export const getDateStringWithoutYear = (date) => {
  const toDate = new Date(date)

  if (toDate.toString() === 'Invalid Date') return

  return toDate.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })
}

export const fixTimezoneAndFormat = (date, removeSecondsAndZone) => {
  if (!date) return
  const timezone = moment().utcOffset() / 60
  const timezoneFixed = moment(date).utcOffset(timezone)
  if (!removeSecondsAndZone) return timezoneFixed.format()
  return timezoneFixed.format('YYYY-MM-DDTHH:mmZ')
}

export const getFormatTime12PM = (date, IANATimezone) => {
  if (!date) return

  let formatTime12PMDate = new Date(date)
  if (formatTime12PMDate.toString() === 'Invalid Date') return

  formatTime12PMDate.setHours(12, 0, 0, 0)
  formatTime12PMDate = changeTimezone(formatTime12PMDate, IANATimezone)
  formatTime12PMDate = fixTimezoneAndFormat(formatTime12PMDate, true)
  return formatTime12PMDate
}

/**
 * Checks if the cohort withdrawal date has ended.
 *
 * @param {string} finalWithdrawalDate - The final withdrawal date in string format.
 * @returns {boolean} - Returns true if the withdrawal date has ended or is invalid, otherwise false.
 */
export const isCohortWithdrawalDateEnded = (finalWithdrawalDate) => {
  if (!finalWithdrawalDate) return false

  const currentDate = new Date()
  const currentFinalWithdrawalDate = convertEasternTimeToLocalTime(finalWithdrawalDate)

  // check If expiredDate is invalid returns true
  if (!currentFinalWithdrawalDate) {
    return true
  }
  return moment(currentDate).isAfter(currentFinalWithdrawalDate)
}
